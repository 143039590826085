@import '../../../styles/_variables';

.select-custom {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: 1px solid $purple-2;
  &:focus {
    outline: none; // Optional: removes the default focus outline
    border-color: darken(
      $purple-2,
      10%
    ); // Optional: darkens the border on focus for better visibility
  }
}

.color-grey {
  color: $grey-2; // Grey color when no selection is made
}

.color-blue {
  color: $blue-2; // Blue color when a selection is made
}

.dropdown-wrapper::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.select-custom option {
  color: inherit; // Ensure options inherit the select color
}
