@import '../../styles/_variables';

#neurotype {
  .share-prompt {
    left: 50%;
    bottom: -13%;

    z-index: 1;
    transform: translate(-50%, -50%);
    button {
      padding: 10px !important;
    }
  }

  .tagline {
    font-family: 'GeoSlab703 Md BT';
    font-style: normal;
    font-weight: 400;
    padding: 1.875rem 1.25rem 1.25rem 1.25rem;
  }

  .cap {
    padding: 1.875rem 1.25rem 1.25rem 1.25rem;

    .cap-title {
      font-family: 'GeoSlab703 Md BT';
      font-size: 1.625rem;
    }
  }

  .content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.75s ease-in-out;
  }

  hr {
    border-bottom: none;
    border-width: 1.5px;
  }

  .expander {
    position: relative;
    width: 25px;
    height: 25px;

    .circle-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: white;
      border-radius: 50%;
    }

    .circle {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to top,
        rgba(59, 133, 201, 0.2) 0%,
        transparent 100%
      );
      border-radius: 50%;
    }

    .triangle {
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 11.25px 6.25px 0 6.25px; /* half the height of the triangle */
      border-color: $purple-2 transparent transparent transparent;

      top: 7px;
      left: 6.25px;
      transition: transform 0.3s ease;

      &.rotate {
        transform: rotate(-180deg);
        border-color: $purple-3 transparent transparent transparent;
      }
    }
  }

  .thick-padding {
    padding: 1.875rem;
  }

  @media (min-width: 1024px) {
    .share-prompt {
      bottom: -12%;
    }
  }
}

#satisfaction-survey {
  .survey-cta {
    border: none;
    box-shadow: none;
    background: white;
  }

  @media (min-width: 1024px) {
    .bg-blue {
      width: 100%;
    }
  }
}
