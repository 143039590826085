.brain {
  width: 70px;
}

.tooltip {
  position: relative;
  max-width: 55vw;
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -22px; /* Position the triangle above the blob */
  left: 37%;
  transform: translateX(-50%); /* Ensures the triangle is perfectly centered */
  width: 0;
  height: 0;
  border-left: 18px solid transparent; /* Size of the triangle */
  border-right: 18px solid transparent; /* Size of the triangle */
  border-bottom: 22px solid #986fcc; /* Color and size of the triangle */
}
