@import '../../styles/_variables';

#app-title {
  h1 {
    color: $purple-1;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);
    line-height: 82%; /* 36.9px */
  }

  .subheading {
    color: $blue-1;
    line-height: 110%; /* 15.4px */
    letter-spacing: 6.02px;
  }
}
