@import 'src/styles/_variables.scss';

.tooltip {
  position: relative;
  max-width: 55vw;
  display: inline-block;
  background-color: $purple-1;
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -22px; /* Position the triangle above the blob */
  left: 42%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 22px solid $purple-1;
}

@media (min-width: 1024px) {
  .tooltip {
    max-width: 30vw;

    &:before {
      left: 72%;
      content: '';
      position: absolute;
      top: -44px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 50px 40px; // Create an asymmetrical triangle
      border-color: transparent transparent $purple-1 transparent;
      transform: skewX(-20deg); // Skew the triangle for the desired slant
    }
  }

  .chunky-radius {
    border-radius: 20px;
  }
}
