$purple-1: #986fcc;
$purple-2: #986ecc;
$purple-3: #d681f3;
$purple-4: #564d8e;
$blue-1: #244db7;
$blue-2: #3156bb;
$blue-3: #3b85c9;
$blue-4: #23408f;
$grey-1: #e0e1ea;
$grey-2: #b3b9ca;
