@import 'src/styles/_variables.scss';

.custom-checkbox-container {
  display: flex;
  align-items: start;
  cursor: pointer;
}

.custom-checkbox {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  margin-right: 12px;
  flex-shrink: 0;
  background-color: $grey-1;
  border-radius: 4px;
  border: 1px solid $purple-2;
  cursor: pointer;
  position: relative;

  &:checked {
    background-color: $grey-1;
    border-color: $purple-2;
  }

  &:checked::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 1px;
    width: 6px;
    height: 11px;
    border: solid $purple-2;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
}
