.policy-text {
  // max-height: 70vh;
  overflow-y: auto;

  p {
    margin-bottom: 1rem;
  }
}

@media (min-width: 1024px) {
  .policy-text {
    max-height: 55vh;
  }
}
