@import 'src/styles/_variables.scss';

.trackerBackground {
  fill: $blue-2;
}

.progressBackground {
  fill: $blue-4;
}

.progressPath {
  fill: $purple-3;
}
