#load-results {
  & > div {
    position: relative;

    .results-teaser {
      position: absolute;
      top: 75%;
    }
  }
}
